import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import culture from './img/logo1.jpg'
import culture2 from './img/logo2.jpg'
import culture3 from './img/logo3.jpg'
import culture4 from './img/logo4.png'
import { Footer } from '../components/footer'

const Div = styled.div`
  .hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 61.8vh;
    max-height: 600px;

    text-align: center;
  }

  h1 {
    font-family: 'GT Walsheim Pro Bold';
  }

  @media (max-width: 600px) {
    img {
      width: 100%;
    }
  }
`

const ImgContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  img {
    display: block;
    width: 100%;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

class Volunteer extends React.Component {
  render() {
    return (
      <Layout location="/volunteer">
        <Div
          style={
            {
              // background: '#f2f1e2',
              // borderRadius: 5,
              // boxShadow: '15px 10px 40px -2px rgba(0,0,0,.8)',
            }
          }
        >
          <Helmet title="Волонтерская деятельность в сфере сохранения и популяризации культурного наследия: лучшие практики и инициативы" />
          <div className="wrapper">
            <h1 className="section-headline">
              Волонтерская деятельность в сфере сохранения и популяризации
              культурного наследия: лучшие практики и инициативы
            </h1>
            <p>При поддержке: </p>
            {/* <p>
              Одной из стратегических задач, стоящих перед нашим обществом,
              является сохранение и актуализация природного и культурного
              наследия как национального достояния народов России. Приобщение
              молодого поколения к деятельности по изучению, популяризации и
              сохранению памятников истории и культуры формирует у него бережное
              отношение к культурному наследию и способствует профессиональному
              просвещению.
            </p> */}
            <ImgContainer>
              <img src={culture} alt="культура" />
              <img src={culture2} alt="культура" />
              <img src={culture3} alt="культура" />
              <img src={culture4} alt="культура" />
            </ImgContainer>
          </div>
        </Div>
        <Footer text="При финансовой поддержке Российского фонда культуры в рамках проекта № 21-3-000455 «Волонтёрская деятельность в сфере сохранения и популяризации культурного наследия: лучшие практики и инициативы»" />
      </Layout>
    )
  }
}

export default Volunteer
